<template>
  <!-- Start Content-->
  <div>
    <Form @submit="SaveParams" :validation-schema="schema">
      <div class="responsive-table-plugin">
        <div class="table-rep-plugin">
          <div class="table-responsive">
            <table class="table table-striped table-borderless">
              <tbody>
                <tr v-for="(value, keyName) in jsonObj" :key="keyName">
                  <th>
                    <!-- {{ keyName }} -->
                    {{$language(keyName) }}
                  </th>
                  <td>
                    <div v-if="exceptionKey.includes(keyName)">{{ value }}</div>
                    <div v-else-if="types[keyName].type === 'date'">
                      <el-date-picker
                        v-model="editData[keyName]"
                        class="w-100"
                        type="date"
                        format="YYYY-MM-DD"
                        :name="keyName"
                      ></el-date-picker>
                      <ErrorMessage class="invalid-feedback" :name="keyName" />
                    </div>
                    <div v-else-if="types[keyName].type === 'time'">
                      <el-time-picker v-model="editData[keyName]" class="w-100" format="HH:mm:ss"></el-time-picker>
                      <ErrorMessage class="invalid-feedback" :name="keyName" />
                    </div>
                    <div v-else-if="types[keyName].type === 'timestamp'">
                      <el-date-picker
                        v-model="editData[keyName]"
                        class="w-100"
                        type="datetime"
                        :name="keyName"
                      ></el-date-picker>
                      <ErrorMessage class="invalid-feedback" :name="keyName" />
                    </div>
                    <div v-else>
                      <Field
                        :name="keyName"
                        :class="'form-control col-10 col-md-12'"
                        v-model="editData[keyName]"
                        type="text"
                      />
                      <ErrorMessage class="invalid-feedback" :name="keyName" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- end .table-responsive -->
        </div>
        <!-- end .table-rep-plugin-->
      </div>

      <div class="row d-flex justify-content-center mt2 mb-3" v-if="isLoading">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <div class="row d-flex justify-content-end align-items-center mt-2 mb-3 px-2" v-else>
        <div class="col-10 mb-0 alert alert-primary" v-if="saveAlert === 1">{{$language('保存しました')}}</div>
        <div
          class="col-10 mb-0 alert alert-danger"
          v-if="saveAlert === 99"
        >{{$language('入力内容に問題があります')}}</div>
        <div
          class="col-10 mb-0 alert alert-danger"
          v-if="saveAlert === 9"
        >{{$language('保存に失敗しました')}}</div>
        <div class="col-2 pr-0">
          <button
            type="submit"
            class="btn btn-primary"
            @click="saveAlert = 99"
          >{{$language('save')}}</button>
        </div>
      </div>
      <!-- end .responsive-table-plugin-->
    </Form>
  </div>
  <!-- container -->
</template>

<script>
import axios from "axios";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { ElDatePicker, ElTimePicker } from "element-plus";
import moment from "moment";

export default {
  name: "BuildArticleTableEdit",
  components: {
    Form,
    Field,
    ErrorMessage,
    ElDatePicker,
    ElTimePicker,
  },
  props: {
    jsonObj: [Object],
    types: [Object],
    viewParams: {
      name: {
        type: String,
        default: "",
      },
      apiUrl: {
        type: String,
        default: "",
      },
      paramKey: String,
    },
  },
  data() {
    let types = this.types;
    let typeSchema = {};
    Object.keys(types).forEach(function (key) {
      switch (types[key].type) {
        case "integer":
          typeSchema[key] = yup
            .number(this.$language("整数の半角数字で入力してください。"))
            .integer(this.$language("整数の半角数字で入力してください。"))
            .min(
              types[key].min,
              types[key].min +this.$language("以上の整数を入力してください。")
            )
            .max(
              types[key].max,
              types[key].max +this.$language('以下の整数を入力してください。')
            )
            .nullable()
            .transform((value, originalValue) =>
              String(originalValue).trim() === "" ? null : value
            )
            .typeError(this.$language("整数の半角数字で入力してください。"));
          break;
        case "number":
          typeSchema[key] = yup
            .number(this.$language('半角数字で入力してください。'))
            .nullable()
            .transform((value, originalValue) =>
              String(originalValue).trim() === "" ? null : value
            )
            .typeError(this.$language('半角数字で入力してください。'));
          break;
        case "date":
          typeSchema[key] = yup
            .date()
            .max("9999-12-31",this.$language('年数は4桁で入力してください。'))
            .nullable()
            .transform((value, originalValue) =>
              String(originalValue).trim() === "" ? null : value
            )
            .typeError(this.$language('YYYY-MM-DDの形式で入力してください。'));
          break;
        case "time":
          typeSchema[key] = yup
            .date()
            .max("9999-12-31 23:59:59")
            .nullable()
            .transform((value, originalValue) =>
              String(originalValue).trim() === "" ? null : value
            )
            .typeError(this.$language('HH:mm:ssの形式で入力してください。'));
          break;
        case "timestamp":
          typeSchema[key] = yup
            .date()
            .max("9999-12-31 23:59:59")
            .nullable()
            .transform((value, originalValue) =>
              String(originalValue).trim() === "" ? null : value
            )
            .typeError(this.$language('YYYY-MM-DD HH:mm:ssの形式で入力してください。'));
          break;
        case "string":
          typeSchema[key] = yup
            .string()
            .max(
              types[key].length,
              types[key].length +this.$language('文字以下で入力してください。')
            )
            .nullable()
            .transform((value, originalValue) =>
              String(originalValue).trim() === "" ? null : value
            );
          break;
        default:
          break;
      }
    });
    yup.number().typeError(this.$language('半角数字で入力してください。'));
    const schema = yup.object(typeSchema);
    return {
      schema,
      isFocus: -1,
      saveAlert: 0, // 保存結果
      exceptionKey: ["BuildingID", "Modified", "ModifiedBy"], // 編集出来ない項目名
      editData: {},
      typeSchema: Object,
      isLoading: false,
    };
  },
  created() {
    let types = this.types;
    let editData = this.editData;
    let jsonObj = this.jsonObj;
    Object.keys(types).forEach(function (key) {
      if (types[key].type === "date") {
        if (jsonObj[key]) {
          editData[key] = new Date(jsonObj[key]);
        } else {
          editData[key] = null;
        }
      } else if (types[key].type === "time") {
        if (jsonObj[key]) {
          editData[key] = new Date("2020/01/01 " + jsonObj[key]);
        } else {
          editData[key] = null;
        }
      } else if (types[key].type === "timestamp") {
        if (jsonObj[key]) {
          editData[key] = new Date(jsonObj[key]);
        } else {
          editData[key] = null;
        }
      } else {
        editData[key] = jsonObj[key];
      }
    });
    this.editData = editData;
  },
  methods: {
    timeCast: function () {
      let types = this.types;
      let editData = this.editData;
      let jsonObj = this.jsonObj;
      Object.keys(types).forEach(function (key) {
        if (types[key].type === "time") {
          if (editData[key]) {
            editData[key] = new Date("2020/01/01 " + jsonObj[key]);
          }
        }
      });
      this.editData = editData;
    },
    SaveParams: function () {
      this.isLoading = true;
      let types = this.types;
      let editData = this.editData;
      Object.keys(types).forEach(function (key) {
        if (types[key].type === "date") {
          if (editData[key]) {
            editData[key] = moment(editData[key]).format("YYYY-MM-DD");
          }
        } else if (types[key].type === "time") {
          if (editData[key]) {
            editData[key] = moment(editData[key])
              .format("YYYY-MM-DD HH:mm:ss")
              .substr(11, 8);
          }
        } else if (types[key].type === "timestamp") {
          if (editData[key]) {
            editData[key] = moment(editData[key]).format("YYYY-MM-DD HH:mm:ss");
          }
        }
      });

      axios
        .put(this.viewParams.apiUrl, {
          data: editData,
        })
        .then(() => {
          this.saveAlert = 1;
          this.isLoading = false;
          this.$emit("editData", editData);
          this.$emit("successSave");
        })
        .catch((error) => {
          this.saveAlert = 9;
          this.isLoading = false;
          this.timeCast();
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.responsive-table-plugin {
  display: flex;
  flex-wrap: wrap;
}

.table-rep-plugin {
  flex: 1;
}

@media screen and (max-width: 768px) {
  .responsive-table-plugin {
    flex-direction: column;
    // height: 90vh;
    width: 100%;
  }

  .table-responsive {
    height: 100%;
    max-height: none;
  }
}

.responsive-table-plugin tbody th {
  font-weight: bold;
  min-width: 12.5em;
  white-space: normal !important;
  width: 25%;
  word-break: break-all;
}

.responsive-table-plugin tbody td {
  white-space: normal !important;
  word-break: break-word;
}

.invalid-feedback {
  display: block;
}
</style>