<template>

  <!-- Start Content-->
  <div>
    <div class="responsive-table-plugin">
      <div class="table-rep-plugin">
        <div class="table-responsive" data-pattern="priority-columns">
          <table class="table table-striped table-borderless">
            <tbody>
              <tr v-for="(value, keyName) in jsonObj" :key="keyName">
                <th>
                  <!-- {{ keyName }} -->
                  {{ $language(keyName) }}
                </th>
                <td>
                  <div>
                    {{ value }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div> <!-- end .table-responsive -->
      </div> <!-- end .table-rep-plugin-->
    </div>
    <!-- end .responsive-table-plugin-->
  </div> <!-- container -->

</template>

<script>
import axios from 'axios';
export default {
  name: 'BuildArticleTable',
  props: {
    jsonObj: [Object],
    viewParams: {
      name: {
        type: String,
        default: ''
      },
      apiUrl: {
        type: String,
        default: '',
      },
      paramKey: String,
    },
  },
  data() {
    return {
      isFocus: -1,
      saveAlert: 0, // 保存結果
    }
  },
  methods: {
    SaveParams: function() {
      axios
        .put(this.viewParams.apiUrl, {
          extended: this.extendParams
        })
        .then(() => {
          this.saveAlert = 1
        })
        .catch(error => {
          this.saveAlert = 9
          console.log(error)
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.responsive-table-plugin {
  display: flex;
  flex-wrap: wrap;
}

.table-rep-plugin {
  flex: 1;
}

@media screen and (max-width: 768px) {

  .responsive-table-plugin {
    flex-direction: column;
    // height: 90vh;
    width: 100%;
  }

  .table-responsive {
    height: 100%;
    max-height: none;
  }

}

.responsive-table-plugin tbody th {
  font-weight: bold;
  min-width: 12.5em;
  white-space: normal !important;
  width: 25%;
  word-break: break-all;
}

.responsive-table-plugin tbody td {
  white-space: normal !important;
  word-break: break-word;
}
</style>